import '../category_price_filter'
import '../category_svg_filter'
import '../zoom'
// import '../../shared/nav/collapsing_nav'  // removed in v3
import '../../shared/phone'
import '../../shared/package_gallery/image_gallery'
import '../../shared/buyers_page_personalizer'

import AddToCartForms from '../../shared/add_to_cart_forms'
import HotelIndex from '../../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../../default/store'
import CartNav from '../../shared/nav/nav_cart'
import Checkout from '../../shared/checkout'
import DepositRedemptions from '../../shared/deposit_redemptions'
import '../stripe_styling'
import '../../default/carousel_bs5'   // added in v3
import '../../shared/hide_map'

import 'bootstrap/dist/js/bootstrap.esm.js'  // added in v3
import '../../shared/nav/bootstrap_nav.js'  // added in v3

import './seating_chart_filter'  // added in v3
import '../filtering_badges.js'
import '../../shared/sentry'
import '../tooltip'
import '../counter'
import '../../shared/package_comparisons'
import '../horizontal_scroll'
import '../../shared/disable_reset' // added in v3
import '../../f1/slide_elems' // added in v3

import VueLoader from '../../default/vue-loader'
import LightBox from '../../shared/package_gallery/lightbox_gallery.vue'
import ImageGallery from '../../shared/package_gallery/package_gallery.vue'
import OrderListBootstrap from '../../default/orders/stripe_orders/order_list_bootstrap'

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  VueLoader.loadVueComponent(ImageGallery, document.getElementById('image_gallery'))
  if (document.getElementById('lightbox_gallery')){
    document.getElementById('lightbox_gallery').classList.add('hide')
  }
  VueLoader.loadVueComponent(LightBox, document.getElementById('lightbox_gallery')) 
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)
  VueLoader.loadVueComponent(OrderListBootstrap, document.getElementById('customer_order_list'))
})

